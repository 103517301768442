import React, { Component } from 'react';

import { BASE_ZINDEX } from '../popup-factory';

const DEFAULT_COLOR = '#5ca3b6';

class PasswordPopup extends Component {

  constructor(props) {
    super(props);

    this.state = {
      password: '',
      failure: false
    };

    this.handleChangePassword = this.handleChangePassword.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChangePassword(e) {
    this.setState({ password: e.target.value });
  }

  handleKeyDown(e) {
    if ('Enter' === e.key) {
        this.handleSubmit(e);
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    this.props.onSubmit(this.state.password).then(
      result => this.setState({ failure: !result })
    );
  }

  render() {
    const { title, subtitle, submitColor = DEFAULT_COLOR, placeholder = 'Enter password', index } = this.props;
    const { password, failure } = this.state;

    return (
      <div className="reveal tiny" style={{ display: 'block', zIndex: BASE_ZINDEX + index, height: 'initial', overflow: 'visible' }}>
        <div className="row small-12 columns">
          <h3 style={{ textAlign: 'center' }}>{title}</h3>
          {subtitle && <p style={{ textAlign: 'center' }}>{subtitle}</p>}
          {failure && <p style={{ textAlign: 'center', color: '#e0150d' }}>Invalid Password</p>}
          <div className="input-group">
            <input className="input-group-field" type="password" value={password} onChange={this.handleChangePassword} placeholder={'🔒 ' + placeholder} onKeyDown={this.handleKeyDown} />
            <div className="input-group-button" style={{ padding: '0 0.5rem', backgroundColor: submitColor }} onClick={this.handleSubmit}>
              <a style={{ fontSize: '2em', lineHeight: 1, verticalAlign: 'middle', color: 'white' }}>￫</a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PasswordPopup;

